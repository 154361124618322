<template>
    <div class="loading" v-show="loading.if_visible.value">
        <svg class="loading_blocks" viewBox="0 0 1000 1000">
            <defs>
                <polygon
                    id="loading_blocks_hexagon"
                    :points="`0,${-p1} ${p2},${-p1/2} ${p2},${p1/2} 0,${p1} ${-p2},${p1/2} ${-p2},${-p1/2}`"
                    fill="#000013"
                />
            </defs>
            <g v-for="l in total_line" :key="l">
                <use
                    v-for="r in total_row"
                    :key="r"
                    class="loading_blocks_block"
                    xlink:href="#loading_blocks_hexagon"
                    :x="l%2?(p2*2-1)*r-p1*2:(p2*2-1)*r+p2-p1*2"
                    :y="l*(p1*2/4*3-0.5)-p1*2"
                    :transform-origin="`${p1} ${p1}`"
                />
            </g>
        </svg>
        <div class="loading_codewall">{{codewall.code}}</div>
        <div class="loading_main">
            <div class="loading_main_ship">
                <Ship_fill />
            </div>
            <p class="loading_main_title">GRAND-STARRS-RAY</p>
            <div class="loading_main_wavebox">
                <div>
                    <img :src="require('../assets/images/loading/waveline.svg')" alt="waveline" />
                    <img :src="require('../assets/images/loading/waveline.svg')" alt="waveline" />
                </div>
                <div>
                    <img :src="require('../assets/images/loading/waveline.svg')" alt="waveline" />
                    <img :src="require('../assets/images/loading/waveline.svg')" alt="waveline" />
                </div>
            </div>
            <div class="loading_main_bottom">
                <p>LOADING/:</p>
                <p>接入--></p>
                <p>飞船系统 +</p>
            </div>
        </div>
    </div>p
</template>

<script setup>
import Ship_fill from "@/components/ship_fill.vue";
import { ref, onMounted, inject } from "vue";
import gsap from "gsap";
// 接收app的check_loading函数
const check_loading = inject("check_loading");
// 六边形矩阵的相关数据
let p1 = 80; //点位数据1
let p2 = (Math.sqrt(3) / 2) * p1; //点位数据2
let total_line = 10; //行总数
let total_row = 10; //列总数
// 代码01墙
const codewall = {
    code: ref(""), //用于显示的01代码文字
    animater: null, //动画播放器
    // 播放动画
    play() {
        this.stop();
        this.animater = setInterval(this.update_code.bind(this), 100);
    },
    // 停止动画
    stop() {
        clearInterval(this.animater);
    },
    // 更新代码文字
    update_code() {
        // 模拟生成代码背景：为了在首屏减少性能负担，所以用两个长字符随机串拼接来实现
        this.code.value = Array.from(
            { length: 30 },
            () =>
                Math.random() > 0.5
                    ? "01000111010100100100000101001110010001000010110101010011010101000100000101010010010100100101001100101101010100100100000101011001" //GRAND-STARRS-RAY 二进制
                    : "011000110110111101110011011011010110100101100011001000000110001001110010011011110111010001101000" //cosmic broth 二进制
        ).join("");
    },
};
const loading = {
    // DOM元素
    blocks: null,
    codewall: null,
    main: null,
    // loading是否可见
    if_visible: ref(true),
    //动画播放器
    animater: null,
    init() {
        this.blocks = document.querySelectorAll(".loading_blocks_block");
        this.codewall = document.querySelector(".loading_codewall");
        this.main = document.querySelector(".loading_main");
    },
    // 展示动画
    show(next) {
        this.if_visible.value = true; //loading可见
        codewall.play(); //代码墙动画播放
        if (this.animater) this.animater.kill(); //如果动画处于进行状态，则停止，避免动画卡顿
        // 动画内容
        this.animater = gsap
            .timeline()
            .set(this.blocks, {
                scale: 1,
            })
            .to(this.blocks, {
                opacity: 1,
                duration: 0.5,
                ease: "power2.out",
                stagger: {
                    from: "random",
                    each: 0.002,
                },
            })
            .to(
                this.blocks,
                {
                    strokeOpacity: 0,
                    strokeDashoffset: () => (Math.random > 0.5 ? 100 : -100),
                    duration: 0.5,
                    ease: "power4.out",
                    stagger: {
                        from: "random",
                        each: 0.002,
                    },
                },
                "<0.3"
            )
            .to(
                this.main,
                {
                    opacity: 1,
                    duration: 1,
                    ease: "power4.out",
                },
                "<"
            )
            .to(
                this.codewall,
                {
                    opacity: 0.05,
                    duration: 1,
                    ease: "power4.out",
                    onComplete: () => {
                        next(); //延迟执行路由跳转
                        check_loading(); //检查网页加载状态
                    },
                },
                "<"
            );
    },
    // 隐藏动画
    hidden() {
        if (this.animater) this.animater.kill(); //如果动画处于进行状态，则停止，避免动画卡顿
        // 动画内容
        this.animater = gsap
            .timeline()
            .set(this.blocks, {
                strokeDashoffset: () => (Math.random > 0.5 ? 100 : -100),
            })
            .to(this.blocks, {
                strokeOpacity: 1,
                strokeDashoffset: 0,
                duration: 0.5,
                ease: "power4.out",
                stagger: {
                    from: "random",
                    each: 0.002,
                },
            })
            .to(
                [this.main, this.codewall],
                {
                    opacity: 0,
                    duration: 1,
                    ease: "power4.out",
                },
                "<"
            )
            .to(
                this.blocks,
                {
                    scale: 0,
                    opacity: 0,
                    duration: 1,
                    ease: "power2.out",
                    stagger: {
                        from: "center",
                        each: 0.004,
                    },
                    onComplete: () => {
                        codewall.stop(); //停止代码墙动画
                        this.if_visible.value = false; //loading不可见
                    },
                },
                "<0.2"
            );
    },
};
onMounted(() => {
    loading.init();
    codewall.play();
});
defineExpose({ loading });
</script>

<style scoped>
.loading {
    --scale: 1;
    position: fixed;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100dvh;
    z-index: 10000000;
}
.loading_blocks {
    position: absolute;
    width: 100%;
    height: auto;
    z-index: -1;
}
@media screen and (max-aspect-ratio: 1/1) {
    .loading_blocks {
        width: auto;
        height: 100%;
    }
}
.loading_blocks_block {
    stroke: var(--theme);
    stroke-width: 0.5;
    stroke-opacity: 0;
    stroke-dasharray: 100;
}
.loading_codewall {
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 120%;
    height: 120%;
    font-family: zpix;
    font-size: calc(var(--scale) * 2rem);
    color: var(--white);
    letter-spacing: calc(var(--scale) * 1rem);
    line-height: calc(var(--scale) * 2.5rem);
    word-break: break-all;
    opacity: 0.05;
}
.loading_main {
    flex-direction: column;
    align-items: center;
}
.loading_main_ship {
    position: relative;
    width: calc(var(--scale) * 5rem);
    height: calc(var(--scale) * 5rem);
    margin-bottom: calc(var(--scale) * 1rem);
}
.loading_main_title {
    font-family: zpix;
    font-size: calc(var(--scale) * 0.8rem);
    color: var(--white);
    letter-spacing: calc(var(--scale) * 0.15rem);
    margin-bottom: calc(var(--scale) * 2.5rem);
}
.loading_main_wavebox {
    justify-content: center;
    margin-bottom: calc(var(--scale) * 2rem);
}
.loading_main_wavebox div {
    position: absolute;
    width: calc(var(--scale) * 10rem);
    overflow: hidden;
    mask-image: linear-gradient(to right, #0000, #000, #0000);
}
.loading_main_wavebox div:nth-child(1) {
    justify-content: start;
}
.loading_main_wavebox div:nth-child(2) {
    justify-content: end;
    opacity: 0.5;
}
.loading_main_wavebox div img {
    width: 100%;
}
.loading_main_wavebox div:nth-child(1) img {
    animation: wave_move1 2s linear infinite;
}
.loading_main_wavebox div:nth-child(2) img {
    animation: wave_move2 2s linear infinite;
}
@keyframes wave_move1 {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-100%);
    }
}
@keyframes wave_move2 {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(100%);
    }
}
.loading_main_bottom {
    justify-content: center;
    margin-right: calc(var(--scale) * -1.5rem);
}
.loading_main_bottom p {
    font-family: zpix;
    font-size: calc(var(--scale) * 0.55rem);
    color: var(--white);
    letter-spacing: calc(var(--scale) * 0.05rem);
    margin-right: calc(var(--scale) * 1.5rem);
}
@media screen and (max-aspect-ratio: 1.5/1) {
    .loading {
        --scale: 1.3;
    }
}
</style>