<template>
    <svg class="ship" viewBox="0 0 500 500">
        <g class="ship_back">
            <path
                class="ship_back_p1"
                style="--t:-15%, 0%;--d:0.15s"
                d="M243.7,393.22l40.19,25.54c-10.94,2.2-22.28,3.35-33.89,3.35c-95.06,0-172.12-77.06-172.12-172.12
            c0-95.03,77.06-172.09,172.12-172.09c0.48,0,0.96,0,1.45,0.03l-25.39,68.92l37.96,54.18l-18.96,41.4l6.96,22.97l34.64,38.95
            L237.4,342.3l7.99,24.96L243.7,393.22z"
            />
            <path
                class="ship_back_p2"
                style="--t:15%, 0%;--d:-0.1s"
                d="M422.12,249.99c0,83.45-59.39,153.04-138.23,168.78l-40.19-25.54l1.69-25.96l-7.99-24.96l49.26-37.96
            l-34.64-38.95l-6.96-22.97l18.96-41.4l-37.96-54.18l25.39-68.92C345.84,78.68,422.12,155.44,422.12,249.99z"
            />
        </g>
        <g class="ship_front">
            <path
                class="ship_front_p1"
                style="--t:-5%, 10%;--d:0.1s"
                d="M379.81,430.59c-36.51,26.32-81.37,41.82-129.82,41.82c-76.04,0-143.15-38.17-183.22-96.39l19.42-47.45
            l47.61-18l50.59,5.16l93.28,4.43l5.82,17.06l68.05,33.1l4.1,37.23L379.81,430.59z"
            />
            <path
                class="ship_front_p2"
                style="--t:-10%, -5%;--d:0s"
                d="M249.98,127.47l-0.57,32.35l-20.44,14.32l-36.93-3.86l-21.32,43.08l13.66,102.36l-50.59-5.16l-47.61,18
            l-19.42,47.45C42.05,340.21,27.6,296.79,27.6,250c0-108.63,77.88-199.11,180.87-218.52l8.2,57.04L249.98,127.47z"
            />
            <path
                class="ship_front_p3"
                style="--t:10%, -5%;--d:-0.15s"
                d="M470.19,218.64l-22.46-0.96l-14.32-16.97l-57.25-7.48l-52.73,16.25l-74.02-49.66l0.57-32.35l-33.31-38.95
            l-8.2-57.04c13.45-2.56,27.32-3.89,41.52-3.89C362.17,27.59,454.97,110.65,470.19,218.64z"
            />
            <path
                class="ship_front_p4"
                style="--t:10%, 5%;--d:0s"
                d="M472.4,250c0,74.38-36.51,140.22-92.59,180.59l-24.18-23.03l-4.1-37.23l-68.05-33.1l12.66-5.34l24.42-11.73
            l20.2-43.32l2.53-54.03l-19.87-13.33l52.73-16.25l57.25,7.48l14.32,16.97l22.46,0.96C471.64,228.9,472.4,239.36,472.4,250z"
            />
        </g>
        <g class="ship_eye">
            <circle cx="250" cy="250" r="103.88" />
            <circle cx="250" cy="250" r="55.6" />
            <circle cx="250" cy="250" r="27.3" />
            <line x1="278.54" y1="202.27" x2="202.86" y2="157.41" />
            <line x1="222.93" y1="201.42" x2="146.25" y2="244.53" />
            <line x1="194.4" y1="249.15" x2="193.38" y2="337.12" />
            <line x1="221.46" y1="297.73" x2="297.14" y2="342.59" />
            <line x1="277.07" y1="298.58" x2="353.75" y2="255.47" />
            <line x1="305.6" y1="250.85" x2="306.62" y2="162.88" />
        </g>
        <circle class="ship_hole" cx="250" cy="250" r="147.62" />
    </svg>
</template>


<style scoped>
.ship {
    position: absolute;
    width: 100%;
    overflow: visible;
}

.ship_back path:nth-child(1) {
    fill: #919090;
}

.ship_back path:nth-child(2) {
    fill: #565656;
}

.ship_front path {
    fill: var(--white);
}
.ship_back path,
.ship_front path {
    animation: ship_part 3s ease infinite var(--d);
}
@keyframes ship_part {
    0% {
        transform: translate(0);
    }
    40% {
        transform: translate(var(--t));
    }
    60% {
        transform: translate(var(--t));
    }
    100% {
        transform: translate(0);
    }
}
.ship_eye circle:nth-child(1) {
    fill: #312d2e;
    stroke: var(--black);
    stroke-width: 3.3;
}

.ship_eye circle:nth-child(2),
.ship_eye line {
    fill: #858179;
    stroke: var(--black);
    stroke-width: 3.3;
}

.ship_eye circle:nth-child(3) {
    fill: var(--white);
    stroke: #8a4342;
    stroke-width: 9;
    animation: ship_eye 1s ease infinite;
}
@keyframes ship_eye {
    0% {
        fill: #8a4342;
    }
    10% {
        fill: var(--white);
    }
    90% {
        fill: var(--white);
    }
    100% {
        fill: #8a4342;
    }
}

.ship_hole {
    fill: none;
    stroke: #868686;
    stroke-width: 15;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 0 93;
    animation: ship_hole 3s ease infinite;
}
@keyframes ship_hole {
    0% {
        stroke-width: 15;
    }
    40% {
        stroke-width: 0;
    }
    60% {
        stroke-width: 0;
    }
    100% {
        stroke-width: 15;
    }
}
</style>