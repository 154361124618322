<template>
    <Loading ref="loading" />
    <router-view />
</template>

<script setup>
import Loading from "@/components/loading.vue";
import { ref, onMounted, provide } from "vue";
import { useRoute } from "vue-router";
import router from "@/router";
import Lenis from "lenis";
const loading = ref(null);
// 检查登录状态
function check_loading() {
    // 每隔一定时间检查加载状态
    let timer = setInterval(() => {
        // 加载完成，清除定时器，并触发后续部分
        if (document.readyState === "complete") {
            clearInterval(timer);
            window.scrollTo(0, 0); //回到页面顶部：确保每次进入页面，页面都在顶端
            check_router(); //检查路由是否正确
            world_controler.check(); // 检查世界观
            world_controler.set_color(); //设置一下对应世界观颜色
            loading.value.loading.hidden(); //隐藏loading动画
        }
    }, 300);
}
// 将check_loading函数传递到loading组件中
provide("check_loading", check_loading);
// 配置路由守卫，加入动画转场
router.beforeEach((to, from, next) => {
    // 如果是同页跳转：则更新路由后直接刷新
    if (to.name == from.name) {
        next();
        // 这里设置一个0秒的定时器是为了让路由刷新后再重刷页面，否则无法跳转到不同参数的同页
        return setTimeout(() => {
            window.location.reload(); //重刷页面
        }, 0);
    }
    if (!from.name) return next(); //首次进入页面没有from，直接跳转
    loading.value.loading.show(next); //延迟路由跳转到loading动画之后进行
});
// 检查路由：如果当前路由不存在或者无query参数，则重新默认跳转
function check_router() {
    const route = router.currentRoute.value;
    const name = route.name;
    const world = route.query.world;
    const node = route.query.node;
    //如果当前页面为主页，则不执行，默认路由正确，因为主页query无参数
    if (name == "home") return;
    // 如果当前为worlds页面
    if (name == "worlds") {
        // 检查是否有world参数或者其对应世界观否存在：因为worlds无node参数，所以不检查node
        if (world == undefined || world_controler.worlds[world] == undefined)
            // 检查结果有误：跳转到默认世界
            router.replace({
                name: "worlds",
                query: {
                    world: world_controler.current_world.name,
                },
            });
    } else {
        // 如果当前为非worlds的其他页面
        let n_world, n_node;
        // 检查是否有world参数或者其对应世界观否存在：
        if (world == undefined || world_controler.worlds[world] == undefined)
            n_world = world_controler.current_world.name; //检查不通过则设置默认参数
        else n_world = world;
        // 检查是否有node参数或者其对应节点否存在：
        if (node == undefined || !world_controler.worlds[n_world].map[name].includes(node))
            n_node = world_controler.worlds[n_world].map[name][0]; //检查不通过则设置默认参数
        else n_node = node;
        // 检查结果有误：query参数发生改变，则跳转
        if (n_world != world || n_node != node)
            router.replace({
                name: name,
                query: {
                    world: n_world,
                    node: n_node,
                },
            });
    }
}
let scroll_controler; //lenis滑动控制器
// 创建lenis滑动控制器
function create_scroll_controler() {
    scroll_controler = new Lenis({
        duration: 1,
        easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // 缓动函数
        smoothWheel: true, // 平滑滚轮滑动
    });
    const raf = (time) => {
        scroll_controler.raf(time);
        requestAnimationFrame(raf);
    };
    requestAnimationFrame(raf);
}
// 世界控制器
const world_controler = {
    // 当前的世界：默认值
    current_world: {
        name: "宇宙汤", //世界观名字
        color: "#178ec5", //主题颜色
        time_diff: 15, //与地球的时间间隔/年
        //介绍
        introduce: "loading",
        //图片链接
        image_url:
            "https://ts1.cn.mm.bing.net/th/id/R-C.92b15f228c60d2cccb97fc84933da139?rik=ZBPlXP%2bmATyFBw&riu=http%3a%2f%2fa1.att.hudong.com%2f75%2f94%2f19300276185521132386943282658.jpg&ehk=t8DtQcvx2Nto4KLIU2piukIYp1LYf0DWgg56HSWSeGM%3d&risl=&pid=ImgRaw&r=0",
        x: 40, //窗景中的x坐标
        y: 60, //窗景中的y坐标
        r: 1, //窗景中的相对星球半径
        //分支节点信息
        map: {
            records: ["loading"],
            portraits: ["loading"],
            images: ["loading"],
        },
    },
    // worlds数据：默认值
    worlds: {
        宇宙汤: {
            name: "宇宙汤",
            color: "#178ec5",
            time_diff: 15,
            introduce: "loading",
            image_url:
                "https://ts1.cn.mm.bing.net/th/id/R-C.92b15f228c60d2cccb97fc84933da139?rik=ZBPlXP%2bmATyFBw&riu=http%3a%2f%2fa1.att.hudong.com%2f75%2f94%2f19300276185521132386943282658.jpg&ehk=t8DtQcvx2Nto4KLIU2piukIYp1LYf0DWgg56HSWSeGM%3d&risl=&pid=ImgRaw&r=0",
            x: 40,
            y: 60,
            r: 1,
            map: {
                records: ["loading"],
                portraits: ["loading"],
                images: ["loading"],
            },
        },
        地平世界: {
            name: "地平世界",
            color: "#4BC0AB",
            time_diff: 20,
            introduce: "loading",
            image_url:
                "https://ts1.cn.mm.bing.net/th/id/R-C.d9560c972b7e6feb34846bdba98fcfce?rik=SP3a%2fkhtBvMT1g&riu=http%3a%2f%2fwww.kuziran.com%2fuploadfile%2f2022%2f1222%2f20221222074133152.jpg&ehk=tZ7Km7rYpMvCsLHi71laHUfiJpXN5tcLIK03TrGiXjY%3d&risl=&pid=ImgRaw&r=0",
            x: 65,
            y: 40,
            r: 1.5,
            map: {
                records: ["loading"],
                portraits: ["loading"],
                images: ["loading"],
            },
        },
        棋盘室: {
            name: "棋盘室",
            color: "#9B3B62",
            time_diff: 50,
            introduce: "loading",
            image_url:
                "https://ts1.cn.mm.bing.net/th/id/R-C.0f7fcadac7c0fa071b824457cfa15a4b?rik=HC353mGqXd3Ing&riu=http%3a%2f%2fimg.likebizhi.com%2fuploads%2flikebizhi%2fup%2f2022%2f08%2f62512a59ac7a7fb17667b6be42e3d1d9398.jpg&ehk=3B5lrzgP139KO5n50XIzl%2bgF%2bbhK2ml76shhC6lq%2bVU%3d&risl=&pid=ImgRaw&r=0",
            x: 45,
            y: 40,
            r: 0.6,
            map: {
                records: ["loading"],
                portraits: ["loading"],
                images: ["loading"],
            },
        },
        迷雾中古: {
            name: "迷雾中古",
            color: "#DEC466",
            time_diff: 100,
            introduce: "loading",
            image_url:
                "https://ts1.cn.mm.bing.net/th/id/R-C.832d03fb6c85cfa402f2f9efc8298aba?rik=Hpd8orjOuo2P%2fg&riu=http%3a%2f%2fimg95.699pic.com%2fphoto%2f50111%2f4951.jpg_wh300.jpg&ehk=MwuNH6t2uzhdIQmEce%2bsmLbBoINLm%2booKqXtXmOEDHc%3d&risl=&pid=ImgRaw&r=0",
            x: 55,
            y: 55,
            r: 0.6,
            map: {
                records: ["loading"],
                portraits: ["loading"],
                images: ["loading"],
            },
        },
    },
    if_checkover: ref(false), //状态值：是否已经检查完路由
    // 检查设置当前世界观
    check() {
        // 获取浏览器url中的world参数
        const w = router.currentRoute.value.query.world;
        if (w) {
            // 如果参数存在：则为非首页功能页面，设置current_world，并记录localStorage
            this.current_world = this.worlds[w];
            localStorage.setItem("current_world", this.current_world.name);
        } else {
            // 参数不存在：为首页，检查localStorage
            if (localStorage.getItem("current_world"))
                // 存在：则直接取localStorage赋值
                this.current_world = this.worlds[localStorage.getItem("current_world")];
            else {
                // 不存在：即为第一次进入该网站，默认为第一个世界观，并记录localStorage
                this.current_world = Object.values(this.worlds)[0];
                localStorage.setItem("current_world", this.current_world.name);
            }
        }
        this.if_checkover.value = true;
    },
    // 设置对应世界的主题颜色
    set_color() {
        document.body.style.setProperty("--theme", this.current_world.color);
    },
    // 设置对应世界的当前时间
    set_time() {
        // 创建基准日期
        const base = new Date();
        base.setFullYear(base.getFullYear() + this.current_world.time_diff); // 设置世界观时差
        // 返回格式化时间
        return new Intl.DateTimeFormat("zh-CN", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: false,
        }).format(base);
    },
};
provide("world_controler", world_controler);
onMounted(() => {
    // 获取临时数据
    (async () => {
        try {
            const response = await fetch("./tem_database/overview.json");
            if (!response.ok) throw new Error("网络错误，无法加载 JSON 文件");
            world_controler.worlds = await response.json();
        } catch (error) {
            console.error("加载 JSON 失败:", error);
        }
    })();
    check_loading();
    create_scroll_controler();
    provide("scroll_controler", scroll_controler);
});
</script>

<style>
* {
    font-size: 2vmin;
    margin: 0;
    padding: 0;
    /* 颜色 */
    --black: #000013;
    --white: #f3f3f3;
}

p {
    user-select: none;
}

img {
    pointer-events: none;
    user-select: none;
}

div {
    display: flex;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

::-webkit-scrollbar {
    display: none;
}

body {
    width: 100%;
    background-color: var(--black);
    background-image: linear-gradient(0deg, transparent 2.9rem, #f3f3f312 3rem),
        linear-gradient(90deg, transparent 2.9rem, #f3f3f312 3rem);
    background-size: 3rem 3rem;
}

@media screen and (max-aspect-ratio: 1.5/1) {
    * {
        font-size: 1vmax;
    }
}

@font-face {
    font-family: zpix;
    src: url(~@/assets/fonts/zpix.ttf);
}
</style>
