import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'home',
    meta: {
      title: 'home',
    },
    component: () => import('../pages/home.vue')
  },
  {
    path: '/worlds',
    name: 'worlds',
    meta: {
      title: 'worlds',
    },
    component: () => import('../pages/worlds.vue')
  },
  {
    path: '/images',
    name: 'images',
    meta: {
      title: 'images',
    },
    component: () => import('../pages/images.vue')
  },
  {
    path: '/records',
    name: 'records',
    meta: {
      title: 'records',
    },
    component: () => import('../pages/records.vue')
  },
  {
    path: '/portraits',
    name: 'portraits',
    meta: {
      title: 'portraits',
    },
    component: () => import('../pages/portraits.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

// 配置路由守卫：每次跳转的时候更新title
router.beforeEach((to, from, next) => {
  document.title = `COSMIC BROTH - ${to.meta.title}`;
  next();
});

export default router

